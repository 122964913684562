import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 11 15">
    <path 
      fill={color}
      d="M5.12617 8.29317C6.79069 8.29317 8.14004 6.94812 8.14004 5.28892C8.14004 3.62972 6.79069 2.28467 5.12617 2.28467C3.46166 2.28467 2.1123 3.62972 2.1123 5.28892C2.1123 6.94812 3.46166 8.29317 5.12617 8.29317Z"
    />
    <path 
      fill={color}
      d="M5.08986 0.635309C4.57349 0.635309 4.07821 0.327181 3.60751 0.470741C3.06305 0.635309 2.83824 1.17803 2.38861 1.50367C1.98115 1.79429 1.34886 1.86082 1.03975 2.25298C0.68497 2.70117 0.818452 3.39445 0.642818 3.87415C0.439084 4.42038 0 4.7145 0 5.32726C0 5.88049 0.467185 6.24814 0.63228 6.74535C0.797376 7.24255 0.656869 7.87632 0.958959 8.29299C1.27861 8.73417 1.92846 8.78319 2.37105 9.10182C2.78906 9.40295 2.99279 9.98419 3.49511 10.1488C3.99742 10.3133 4.5735 9.97018 5.13201 9.97018C5.7116 9.97018 6.23147 10.3098 6.74784 10.1313C7.24664 9.95968 7.45388 9.35743 7.86838 9.0493C8.28287 8.74117 8.91164 8.72717 9.21724 8.30699C9.52285 7.88682 9.41395 7.1235 9.58256 6.6228C9.74766 6.12209 10.1867 5.88399 10.1867 5.32726C10.1867 4.77053 9.68443 4.33284 9.51933 3.83214C9.3437 3.30342 9.49826 2.71867 9.16807 2.28449C8.89056 1.91334 8.14588 1.75928 7.77705 1.47916C7.34148 1.14302 7.26771 0.698335 6.73379 0.519761C6.22094 0.344689 5.66242 0.635309 5.08986 0.635309ZM5.1285 8.98978C3.09817 8.98978 1.45424 7.3476 1.45424 5.32726C1.45424 3.30692 3.09817 1.66124 5.1285 1.66124C7.15882 1.66124 8.80275 3.30342 8.80275 5.32726C8.80275 7.3511 7.15882 8.98978 5.1285 8.98978Z"
    />
    <path 
      fill={color}
      d="M2.35665 9.91406C2.35665 9.91406 3.36479 10.4603 3.69849 10.5408C3.9514 10.6003 4.81552 10.6669 4.93144 10.6844L3.78279 14.1158L2.75358 13.0654L1.20801 13.3455L2.35665 9.91406Z"
    />
    <path 
      fill={color}
      d="M5.47266 10.6882C5.47266 10.6882 6.61076 10.5202 6.92339 10.3836C7.16225 10.2786 7.88937 9.81286 7.99475 9.75684L9.15394 13.1848L7.69618 12.9642L6.63184 14.1161L5.47266 10.6882Z"
    />
  </svg>
);
